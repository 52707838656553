import { useEffect, useRef, useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import Cropper from "cropperjs";
import cv from "./opencv.js";
import "./App.css";
import "survey-core/defaultV2.min.css";
import { createWorker } from "tesseract.js";
import "cropperjs/dist/cropper.css";
import surveyJson from "./surveyJSON.js"; // Survey config

const createFileFromUrl = function (path, url, callback) {
  let request = new XMLHttpRequest();
  request.open("GET", url, true);
  request.responseType = "arraybuffer";
  request.onload = function (ev) {
    if (request.readyState === 4) {
      if (request.status === 200) {
        let data = new Uint8Array(request.response);
        cv.FS_createDataFile("/", path, data, true, false, false);
        // callback();
      } else {
        console.log("Failed to load " + url + " status: " + request.status);
      }
    }
  };
  request.send();
};

function App() {
  const [imageData, setImageData] = useState(null); // uploaded image dataURL
  const [loading, setLoading] = useState(false);
  const [worker, setWorker] = useState(null); // tesseract worker
  const [survey, setSurvey] = useState(null); // survey.js
  const croppedRect = useRef(null); // rectangle for croper tool
  const setCroppedRect = (newValue) => {
    croppedRect.current = newValue;
  };
  const [ocrResult, setOcrResult] = useState(null);
  const [reading, setReading] = useState("");
  const [refreshTesseract, setRefreshTesseract] = useState(null); // refresh recognize
  const canvasRef = useRef(null); // main canvas with the cropper tool
  const canvasCroppedRef = useRef(null); // cropped image
  const ocrResultRef = useRef(null);
  const canvasResizedRef = useRef(null);

  // prepare tesseract worker
  useEffect(() => {
    (async () => {
      const w = await createWorker({
        langPath: "tesseract",
        gzip: false,
        // logger: (m) => console.log(m),
      });
      await w.loadLanguage("eng+ssd_int");
      await w.initialize("eng+ssd_int");
      await w.setParameters({
        tessedit_char_whitelist: "0123456789.",
      });
      setWorker(w);
    })();
    setSurvey(new Model(surveyJson));
    createFileFromUrl("cascade.xml", "cascade.xml", () => null);
  }, []);

  // AI recognize the display area when imageData is uploaded
  // on the assumption that it is the largest rectangle in the image
  useEffect(() => {
    if (imageData && worker) {
      let image = new Image();
      image.src = imageData;
      image.width = 800;
      image.height = (image.naturalHeight / image.naturalWidth) * image.width;
      image.onload = () => {
        let src = cv.imread(image);
        let gray = new cv.Mat();
        console.log(src.cols);
        // cv.resize(
        //   src,
        //   src,
        //   new cv.Size(0, 0),
        //   1000 / src.cols,
        //   1000 / src.cols,
        //   cv.INTER_LINEAR
        // );
        cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY, 0);
        let displays = new cv.RectVector();
        let classifier = new cv.CascadeClassifier();

        // load pre-trained classifiers
        classifier.load("cascade.xml");
        // detect displays
        let msize = new cv.Size(0, 0);
        classifier.detectMultiScale(gray, displays, 1.1, 3, 0, msize, msize);

        // find the largest rectangle
        let rectMax = {
          x: 10,
          y: 10,
          width: 100,
          height: 100,
        };
        for (let i = 0; i < displays.size(); ++i) {
          let rect = displays.get(i);
          if (
            !rectMax ||
            rect.width * rect.height > rectMax.width * rectMax.height
          ) {
            rectMax = rect;
          }
        }

        cv.imshow(canvasRef.current, src);
        // trim the image
        rectMax = trimImage(image, rectMax);
        // show up the cropper tool
        setCroppedRect(rectMax);
        const cropper = new Cropper(canvasRef.current, {
          data: rectMax,
          zoomOnTouch: false,
          zoomOnWheel: false,
          dragMode: "crop",
          toggleDragModeOnDblclick: false,
          // crop event
          crop(event) {
            setCroppedRect(event.detail);
          },
          // refresh tesseract recognition
          cropend: (e) => setRefreshTesseract((prev) => prev + 1),
          ready: (e) => setRefreshTesseract((prev) => prev + 1),
        });
        return () => {
          cropper.destroy();
        };
      };
    }
  }, [imageData, worker]);

  const trimImage = (image, rect) => {
    let croppedImage = cv.imread(image);
    // get the image inside the rectangle rect
    croppedImage = croppedImage.roi(rect);
    // threshold
    cv.cvtColor(croppedImage, croppedImage, cv.COLOR_RGBA2GRAY, 1);
    cv.threshold(croppedImage, croppedImage, 50, 255, cv.THRESH_OTSU);

    // check if needs trimming
    // if the dark area is large then trim the outside
    if (cv.mean(croppedImage)[0] < 150) {
      console.log("trim start", rect);
      let contours = new cv.MatVector();
      let hierarchy = new cv.Mat();
      // Find contours
      cv.findContours(
        croppedImage,
        contours,
        hierarchy,
        cv.RETR_CCOMP,
        cv.CHAIN_APPROX_SIMPLE
      );

      // find the largest contour
      let contourMax = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };
      for (let i = 0; i < contours.size(); ++i) {
        let tmp = contours.get(i);
        let rect = cv.boundingRect(tmp);
        let area = rect.width * rect.height;

        if (!contourMax || area * 1.5 > contourMax.width * contourMax.height) {
          contourMax = {
            x: rect.x + 5,
            y: rect.y + 5,
            width: rect.width - 10,
            height: rect.height - 10,
          };
        }
      }

      // coordinates wrt whole image
      contourMax = {
        ...contourMax,
        x: rect.x + contourMax.x,
        y: rect.y + contourMax.y,
      };

      console.log("trim end", contourMax);
      // check for trim again (stop if overtrimmed)
      if (contourMax.width > 10 && contourMax.height > 10) {
        return trimImage(image, contourMax);
      }
    }
    // returned original rect if trimming not needed
    return rect;
  };

  // OCR - tesseract
  const recognize = (rect) => {
    setLoading(true);
    const image = new Image();
    image.src = canvasRef.current.toDataURL();
    image.onload = () => {
      let croppedImage = cv.imread(image);

      croppedImage = croppedImage.roi(rect);
      // draw image to be sent by email
      cv.imshow(canvasResizedRef.current, croppedImage);
      // cv.cvtColor(croppedImage, croppedImage, cv.COLOR_RGBA2GRAY, 1);
      // cv.threshold(croppedImage, croppedImage, 50, 255, cv.THRESH_OTSU);

      // let M = cv.Mat.ones(2, 2, cv.CV_8U);
      // let anchor = new cv.Point(-1, -1);
      // cv.erode(
      //   croppedImage,
      //   croppedImage,
      //   M,
      //   anchor,
      //   1,
      //   cv.BORDER_CONSTANT,
      //   cv.morphologyDefaultBorderValue()
      // );

      cv.imshow(canvasCroppedRef.current, croppedImage);

      // // begin tesseract recognition
      (async () => {
        const result = await worker.recognize(canvasCroppedRef.current);
        setLoading(false);
        // setOcrResult(result);
        setReading(Number(result.data.text.replaceAll(" ", "")));
      })();
    };
  };

  // run tesseract recognize after crop
  useEffect(() => {
    if (croppedRect.current) {
      recognize(croppedRect.current);
    }
  }, [refreshTesseract]);

  // survey events
  useEffect(() => {
    if (survey) {
      // show cropping tool when photo is uploaded
      survey.onValueChanged.add((survey, options) => {
        if (options.name === "MeterPhoto") {
          setImageData(survey.data.MeterPhoto?.[0].content);
        }
      });
      // email when completed
      survey.onComplete.add((survey, options) => {
        const data = survey.data;
        delete data.MeterPhoto;
        options.showDataSaving();
        sendEmail(data).then((d) => {
          if (d.status === "success") {
            options.showDataSavingSuccess(
              "Thanks for submitting your meter reading. An email has been sent."
            );
          } else {
            options.showDataSavingError(
              "There was an error. Email was not sent. Please try again."
            );
          }
        });
      });
    }
  }, [survey]);

  const sendEmail = (data) => {
    return fetch("/sendemail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sender: "meter-reader",
        html_body: `<h3>Meter Reading</h3>
      <h5>First Name</h5>${data.FirstName}
      <h5>Last Name</h5>${data.LastName}
      <h5>Post Code</h5>${data.PostCode}
      <h5>Address</h5>${data.Address}
      <h5>Meter Reading</h5>${data.MeterReading}
      <h5>Time of Reading</h5>${new Date().toString()}
      ${
        data.CroppedPhoto
          ? `<h5>Photo</h5><img src="${data.CroppedPhoto}" />`
          : ``
      }
      `,
      }),
    }).then((res) => res.json());
  };

  return (
    <div>
      {survey && <Survey model={survey} />}
      {loading && (
        <div
          style={{
            color: "white",
            position: "fixed",
            width: 600,
            top: "calc(50% - 250px)",
            left: "calc(50% - 300px)",
            padding: 150,
            background: "rgba(25,179,148,0.8)",
            zIndex: 5,
            fontSize: 30,
          }}
          onClick={() => setLoading(false)}
        >
          Loading...
        </div>
      )}
      {imageData && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            background: "rgba(0,0,0,0.2)",
            width: "100%",
            height: "100vh",
            zIndex: 3,
            padding: 20,
          }}
          onClick={() => setImageData(null)}
        >
          <div
            style={{
              background: "white",
              margin: "auto",
              width: "95%",
              marginTop: "5%",
              maxHeight: "95%",
              padding: 20,
              overflow: "auto",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              style={{ float: "right" }}
              onClick={() => {
                setImageData(null);
              }}
            >
              X
            </button>{" "}
            <h3>Select the area</h3>
            <div>
              <canvas
                style={{ maxWidth: "100%", display: "block", maxHeight: 500 }}
                ref={canvasRef}
                onTouchStart={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
                onDragStart={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              ></canvas>
            </div>
            <div>
              detected display:
              <br />
              <canvas
                ref={canvasCroppedRef}
                style={{ maxWidth: "100%" }}
              ></canvas>
              <canvas
                ref={canvasResizedRef}
                style={{ display: "none" }}
              ></canvas>
              <canvas id="contours" style={{ display: "none" }}></canvas>
              <h3>Recognized reading: </h3>
              <input
                type="number"
                className="sd-input sd-text"
                style={{ marginBottom: 10 }}
                value={reading}
                onChange={(e) => setReading(e.target.value)}
              />
              {reading ? (
                <div>
                  Edit the reading or redraw the rectangle if needed. Click
                  accept button when finished.
                </div>
              ) : (
                <div style={{ color: "red" }}>
                  Nothing detected. Please draw a rectangle around the meter
                  display or enter manually.
                </div>
              )}
              <button
                style={{ float: "right" }}
                className="sd-btn sd-btn--action sd-navigation__complete-btn"
                onClick={() => {
                  setImageData(null);
                  survey.getQuestionByName("MeterReading").value = reading;
                  survey.getQuestionByName("CroppedPhoto").imageLink =
                    canvasResizedRef.current.toDataURL();
                  survey.getQuestionByName("CroppedPhoto").value =
                    canvasResizedRef.current.toDataURL();
                  survey.getQuestionByName("MeterReading").visible = true;
                  survey.getQuestionByName("CroppedPhoto").visible = true;
                }}
              >
                Accept reading
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
