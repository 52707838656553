const surveyJson = {
  logo: "logo.png",
  logoWidth: "119",
  logoHeight: "45",
  logoFit: "fit",
  title: "Meter Reading Form",
  showCompletedPage: "true",
  completedHtml:
    "<h1>Many thanks would you like to provide a read for another fuel?</h1>",
  elements: [
    {
      type: "panel",
      elements: [
        {
          type: "html",
          html:
            "<br>Many thanks for completing the Opus meter reads form.<br>" +
            "<br>Your customer information:" +
            "<table class='table'>" +
            "<tr><td>Name:  </td><td>Peter Tanner</td></tr>" +
            "<tr><td>Customer number:</td><td>100034</td></tr>" +
            "<tr><td>Energy type:</td><td>Electricity</td></tr>" +
            "<tr><td>Your last read:</td><td>1200087</td></tr>" +
            "<tr><td>Taken on:</td><td>12/12/2022</td></tr>" +
            "</table>" +
            "Please click <a href='#'> here </a> if any of the above is wrong.",
        },
      ],
    },
    {
      name: "FirstName",
      title: "Enter your first name",
      type: "text",
      isRequired: "true",
      // visible: "false",
    },
    {
      name: "LastName",
      title: "Enter your last name",
      type: "text",
      isRequired: "true",
      // visible: "false",
    },
    {
      name: "PostCode",
      title: "Enter your post code",
      type: "text",
      isRequired: "true",
      // visible: "false",
    },
    {
      name: "Address",
      title: "Enter your address",
      type: "text",
      isRequired: "true",
      // visible: "false",
    },
    {
      name: "ReadingType",
      title: "How would you like to enter your meter reading?",
      type: "radiogroup",
      choices: ["Manual", "Upload photo"],
      isRequired: "true",
      // defaultValue: "Manual",
    },
    {
      name: "MeterPhoto",
      visibleIf: "{ReadingType} = 'Upload photo'",
      title: "Upload your reading",
      type: "file",
      isRequired: "true",
    },
    {
      title: "Cropped image",
      type: "image",
      name: "CroppedPhoto",
      visible: "false",
    },
    {
      name: "MeterReading",
      visibleIf: "{ReadingType} = 'Manual'",
      title: "Meter reading",
      type: "text",
      inputType: "number",
      isRequired: "true",
    },
    {
      type: "panel",
      visibleIf: "{MeterReading} > 1500087 OR {MeterReading} < 1200087",
      elements: [
        {
          type: "html",
          html: "<br>The meter read seems too high/low to be accurate. Would you like to check it? <br>",
        },
      ],
    },
  ],
};

export default surveyJson;
